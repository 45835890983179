import { zIndex } from '../../../config';
import { MeetingLayoutSpec } from '../../../interfaces';
import { MDGlobalSpec } from './MDGlobal';

export const MDLandscapeNormalSpec: MeetingLayoutSpec = {
  thumbnailParticipant: {
    width: 257,
    height: 140
  },
  thumbnailSelf: {
    width: 140,
    height: 78
  },
  thumbnailRadius: 5,
  thumbnailSpacing: 10,
  get videosWrapper() {
    return {
      width: this.thumbnailParticipant.width + this.thumbnailSpacing * 4
    };
  },
  get contentArea() {
    return {
      top: MDGlobalSpec.logoSectionHeight,
      left: this.videosWrapper.width
    };
  }
};

const {
  thumbnailParticipant,
  thumbnailRadius,
  thumbnailSpacing,
  contentArea,
  videosWrapper,
  thumbnailSelf
} = MDLandscapeNormalSpec;

const presentationParticipantSize =
  thumbnailParticipant.width / 3 - thumbnailSpacing / 1.5;

export const MDLandscape = () => (
  <style jsx global>{`
    .normal-mode.MDLandscape .logo-section,
    .normal-mode.MDLandscape .timer-section {
      left: ${contentArea.left}px;
    }
    .normal-mode.content-full.MDLandscape .logo-section,
    .normal-mode.content-full.MDLandscape .timer-section {
      left: 0;
    }
    .normal-mode.MDLandscape .video-thumbnail {
      border-radius: ${thumbnailRadius}px;
      margin: ${thumbnailSpacing}px;
    }
    .normal-mode.MDLandscape .video-thumbnail video {
      border-radius: ${thumbnailRadius}px;
    }
    .normal-mode.MDLandscape #participant-wrapper {
      margin-top: 0;
    }
    .normal-mode.MDLandscape #videos-wrapper .video-thumbnail {
      width: ${thumbnailParticipant.width}px;
      height: ${thumbnailParticipant.height}px;
      display: block;
    }

    .normal-mode.MDLandscape #local-wrapper .local-video-container {
      width: ${thumbnailSelf.width}px;
      height: ${thumbnailSelf.height}px;
      margin: 0;
      display: block;
    }

    .normal-mode.MDLandscape #videos-wrapper {
      position: fixed;
      left: 0;
      top: ${contentArea.top / 2 +
      thumbnailSelf.height +
      thumbnailSpacing * 2}px;
      bottom: 0;
      overflow: auto;
      width: ${videosWrapper.width}px;
    }

    .normal-mode.MDLandscape #local-wrapper {
      position: fixed;
      top: ${contentArea.top / 2}px;
      left: ${thumbnailSpacing * 2}px;
      z-index: ${zIndex.meetingVideosWrapper};
      width: ${thumbnailSelf.width}px;
      height: ${thumbnailSelf.height}px;
    }

    .normal-mode.MDLandscape #participant-wrapper .scroll-container {
      margin: 0;
    }

    .normal-mode.MDLandscape .vb-page-wrapper,
    .normal-mode.MDLandscape .popup-page,
    .normal-mode.MDLandscape .popup-page-content,
    .normal-mode.MDLandscape .PopupContainer,
    .normal-mode.MDLandscape .SBDocumentViewer {
      left: ${contentArea.left || 0}px !important;
      bottom: 0 !important;
      right: 0 !important;
    }
    .normal-mode.MDLandscape .SBDocumentViewer {
      width: calc(100% - ${contentArea.left || 0}px) !important;
      height: calc(100% - ${contentArea.top || 0}px) !important;
    }

    .normal-mode.MDLandscape .SBDocumentViewer.document-fullscreen {
      width: 100% !important;
      left: 0 !important;
    }
    .normal-mode.canControl.MDLandscape:not(.no-controls) .SBDocumentViewer,
    .normal-mode.document-controller.MDLandscape:not(.no-controls)
      .DocumentViewer {
      bottom: 50px !important;
    }

    .normal-mode.MDLandscape .psExperience .popup-page,
    .normal-mode.MDLandscape .psExperience .popup-page-content {
      left: auto !important;
    }

    .normal-mode.MDLandscape .vb-page-wrapper,
    .normal-mode.MDLandscape .popup-page,
    .normal-mode.MDLandscape .popup-page-content,
    .normal-mode.MDLandscape .SBDocumentViewer {
      top: ${contentArea.top || 0}px !important;
    }

    .normal-mode.MDLandscape .SBDocumentViewer.document-fullscreen {
      left: 0 !important;
    }

    .normal-mode.MDLandscape .iframe-container {
      width: 100%;
    }

    .normal-mode.MDLandscape .psExperience .popup-page-content {
      width: min(40vw, 700px) !important;
    }

    .normal-mode.MDLandscape
      .psExperience
      .popup-page-content.viewpage-product-compare {
      width: calc(100vw - ${videosWrapper.width}px) !important;
    }

    .normal-mode.MDLandscape .enlarge-video .video-thumbnail,
    .normal-mode.MDLandscape .one-third-wrapper {
      top: ${contentArea.top}px !important;
      left: ${contentArea.left}px !important;
    }

    .normal-mode.MDLandscape .meeting-controls {
      left: ${videosWrapper.width}px;
      bottom: 10px;
    }

    .one-third.MDLandscape {
      --normal-content-width: calc(100vw - ${contentArea.left}px);
      --onethird-content-width: calc(0.33333 * var(--normal-content-width));
      --onethird-left: calc(
        ${contentArea.left}px + var(--onethird-content-width)
      );
    }

    .normal-mode.one-third.MDLandscape .SBDocumentViewer {
      width: unset !important;
    }
    .normal-mode.content-full.MDLandscape .meeting-controls {
      left: 0;
    }

    .normal-mode.MDLandscape .video-wrapper-bg {
      top: 0;
      right: auto;
      width: ${videosWrapper.width}px;
    }

    .has-presenter.MDLandscape #participant-wrapper .video-thumbnail {
      width: ${presentationParticipantSize}px;
      height: ${presentationParticipantSize}px;
      margin: ${thumbnailSpacing / 2}px;
    }

    .has-presenter.MDLandscape #participant-wrapper .display-name {
      display: none !important;
    }

    .has-presenter.MDLandscape #participant-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding-top: ${thumbnailSpacing}px;
    }

    .has-presenter.MDLandscape #presenter-wrapper {
      display: block;
    }
    .has-presenter.MDLandscape #presenter-wrapper .video-thumbnail {
      margin: ${thumbnailSpacing}px auto;
    }

    .has-presenter.MDLandscape #presenter-wrapper,
    .has-presenter.MDLandscape #participant-wrapper {
      margin: 0;
    }

    .has-presenter.MDLandscape #videos-wrapper {
      flex-direction: column;
    }
    .normal-mode.MDLandscape .video-content-area {
      left: ${contentArea.left}px !important;
      top: ${contentArea.top}px !important;
    }
  `}</style>
);
export default MDLandscape;
