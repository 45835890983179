import { zIndex } from '../../../config';
import { MeetingLayoutSpec } from '../../../interfaces';
import { SMGlobalSpec } from './SMGlobal';

export const SMLandscapeNormalSpec: MeetingLayoutSpec = {
  thumbnailParticipant: { width: 124, height: 74 },
  thumbnailSelf: {
    width: 68,
    height: 40
  },
  thumbnailRadius: 5,
  thumbnailSpacing: 5,
  get videosWrapper() {
    return {
      width: this.thumbnailParticipant.width + this.thumbnailSpacing * 4
    };
  },
  get contentArea() {
    return {
      top: SMGlobalSpec.logoSectionHeight,
      left: this.videosWrapper.width
    };
  }
};

const {
  thumbnailParticipant,
  thumbnailRadius,
  thumbnailSpacing,
  contentArea,
  videosWrapper,
  thumbnailSelf
} = SMLandscapeNormalSpec;

const presentationParticipantSize =
  thumbnailParticipant.width / 2 - thumbnailSpacing / 1.5;

export const SMLandscapeNormal = () => (
  <style jsx global>{`
    .normal-mode.SMLandscape .logo-section,
    .normal-mode.SMLandscape .timer-section {
      left: ${contentArea.left}px;
    }
    .normal-mode.content-full.SMLandscape .logo-section,
    .normal-mode.content-full.SMLandscape .timer-section {
      left: 0;
    }
    .normal-mode.SMLandscape .video-thumbnail {
      border-radius: ${thumbnailRadius}px;
      margin: ${thumbnailSpacing}px;
    }
    .normal-mode.SMLandscape .video-thumbnail video {
      border-radius: ${thumbnailRadius}px;
    }

    .normal-mode.SMLandscape #participant-wrapper {
      margin-top: 0;
    }

    .normal-mode.SMLandscape #videos-wrapper .video-thumbnail {
      width: ${thumbnailParticipant.width}px;
      height: ${thumbnailParticipant.height}px;
      display: block;
    }

    .normal-mode.SMLandscape #local-wrapper .local-video-container {
      width: ${thumbnailSelf.width}px;
      height: ${thumbnailSelf.height}px;
      margin: 0;
      display: block;
    }

    .normal-mode.SMLandscape #videos-wrapper {
      position: fixed;
      left: 0;
      top: ${contentArea.top / 2 +
      thumbnailSelf.height +
      thumbnailSpacing * 2}px;
      bottom: 0;
      overflow: auto;
      width: ${videosWrapper.width}px;
    }

    .normal-mode.SMLandscape #local-wrapper {
      position: fixed;
      top: ${contentArea.top / 2}px;
      left: ${thumbnailSpacing * 2}px;
      z-index: ${zIndex.meetingVideosWrapper};
      width: ${thumbnailSelf.width}px;
      height: ${thumbnailSelf.height}px;
    }
    .normal-mode.SMLandscape #local-wrapper .video-thumbnail,
    .has-presenter.SMLandscape #local-wrapper .video-thumbnail,
    .normal-mode.SMLandscape #participant-wrapper .scroll-container {
      margin: 0;
    }

    .normal-mode.SMLandscape .vb-page-wrapper,
    .normal-mode.SMLandscape .popup-page,
    .normal-mode.SMLandscape .popup-page-content,
    .normal-mode.SMLandscape .SBDocumentViewer {
      top: ${contentArea.top}px !important;
      left: ${contentArea.left}px !important;
      bottom: 0 !important;
      right: 0 !important;
    }

    .normal-mode.SMLandscape .SBDocumentViewer {
      width: calc(100% - ${contentArea.left || 0}px) !important;
      height: calc(100% - ${contentArea.top || 0}px) !important;
    }

    .normal-mode.SMLandscape .SBDocumentViewer.document-fullscreen {
      left: 0 !important;
      width: 100% !important;
    }

    .normal-mode.SMLandscape .enlarge-video .video-thumbnail {
      top: ${contentArea.top}px !important;
      left: ${contentArea.left}px !important;
    }

    .normal-mode.SMLandscape #ToolTipWrapper.mobile-fixed {
      margin-left: ${videosWrapper.width / 2}px;
      margin-top: ${contentArea.top / 2}px;
    }

    .normal-mode.SMLandscape .meeting-controls {
      left: ${videosWrapper.width}px;
      bottom: 10px;
    }

    .normal-mode.content-full.SMLandscape .meeting-controls {
      left: 0;
    }

    .normal-mode.SMLandscape .video-wrapper-bg {
      top: 0;
      right: auto;
      width: ${videosWrapper.width}px;
    }

    .has-presenter.SMLandscape #participant-wrapper .video-thumbnail {
      width: ${presentationParticipantSize}px;
      height: ${presentationParticipantSize}px;
      margin: ${thumbnailSpacing / 2}px;
    }

    .has-presenter.SMLandscape #participant-wrapper .display-name {
      display: none !important;
    }

    .has-presenter.SMLandscape #participant-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    .has-presenter.SMLandscape #presenter-wrapper {
      display: block;
    }
    .has-presenter.SMLandscape #presenter-wrapper .video-thumbnail {
      margin: ${thumbnailSpacing}px auto;
    }
    .has-presenter.SMLandscape #presenter-wrapper,
    .has-presenter.SMLandscape #participant-wrapper {
      margin: 0;
    }

    .has-presenter.SMLandscape #videos-wrapper {
      flex-direction: column;
    }

    .normal-mode.SMLandscape .video-content-area {
      left: ${contentArea.left}px !important;
      top: ${contentArea.top}px !important;
    }

    .normal-mode.SMLandscape.one-third .one-third-wrapper {
      top: ${contentArea.top}px !important;
      left: ${contentArea.left || 0}px !important;
      bottom: ${contentArea.bottom || 0}px !important;
    }
    .normal-mode.SMLandscape {
      --temp: calc(100vw - ${contentArea.left}px);
      --onethird-left: calc(${contentArea.left}px + calc(var(--temp) / 3));
    }

    .normal-mode.SMLandscape.one-third .SBDocumentViewer {
      width: unset !important;
      height: unset !important;
    }
    .normal-mode.one-third.SMLandscape .popup-page-content,
    .normal-mode.one-third.SMLandscape .popup-page,
    .normal-mode.one-third.SMLandscape .SBDocumentViewer {
      left: var(--onethird-left) !important;
      bottom: ${contentArea.bottom} !important;
    }
  `}</style>
);

export default SMLandscapeNormal;
