import React from 'react';
import { meetingColor } from '../../../utils/meeting';

export const SMGlobalSpec = {
  logoSectionHeight: 35,
  meetingControlsHeight: 55
};

const SMGlobal = () => (
  <style jsx global>{`
    .canControl.SM .controls-group {
      position: fixed;
      right: 0;
      bottom: 0;
      top: ${SMGlobalSpec.logoSectionHeight}px;
      transform: translateX(0);
      transition: transform 0.2s ease-in;
      overflow: auto;
    }
    .canControl.SM.no-controls .controls-group {
      transform: translateX(100%);
    }

    .canControl.SM .MainControls .meeting-button {
      height: 55px;
      padding: 0 20px;
    }
    .canControl.SM .MainControls .meeting-button svg {
      width: 30px;
      height: auto;
      margin-right: 5px;
    }
    .canControl.SM .MainControls .meeting-button span {
      font-size: 14px;
      letter-spacing: 0.3px;
    }
    .SM .meet-fullscreen {
      height: ${SMGlobalSpec.logoSectionHeight}px;
    }

    .canControl.SM .meet-fullscreen {
      right: 40px;
    }

    .canControl.SM .meet-fullscreen svg {
      width: 30px;
    }

    .canControl.SM .control-hide-button {
      position: fixed;
      right: 2.5px;
      top: ${SMGlobalSpec.logoSectionHeight / 2}px;
      transform: translateY(-50%) rotate(-90deg);
      transition: transform 0.2s ease-in;
    }

    .canControl.SM .control-hide-button svg {
      height: ${SMGlobalSpec.logoSectionHeight - 5}px;
    }

    .canControl.SM.no-controls .control-hide-button {
      transform: translateY(-50%) rotate(90deg);
    }

    .canControl.SMPortrait .controls-group {
      width: 250px;
    }
    .canControl.SMPortrait .group-item {
      border-top: 0.5px solid ${meetingColor.border};
      padding: 10px 0;
    }
    .canControl.SMPortrait .primary-group {
      border: none;
    }
    .canControl.SMLandscape .group-main {
      display: flex;
      flex-wrap: wrap;
    }
    .canControl.SMLandscape .primary-group,
    .canControl.SMLandscape .secondary-group {
      border: none;
      border-right: 0.5px solid ${meetingColor.border};
    }
    .canControl.SM .icon-only-group {
      display: none;
    }

    .canControl.SMLandscape .MainControls .meeting-button {
      width: 210px;
      text-align: left;
    }

    .canControl.SMLandscape
      .MainControls
      .slide-menu-only-group
      .meeting-button {
      width: 250px;
    }
    .canControl.SMLandscape .last-group {
      border-top: 0.5px solid ${meetingColor.border};
      display: flex;
    }

    .SMLandscape #videos-wrapper > .scroll-container {
      margin: 0 auto auto;
    }
  `}</style>
);

export default SMGlobal;
