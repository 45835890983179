import React from 'react';
import { zIndex } from '../../config';
import { ViewportType } from '../../interfaces';
import { getViewportType, meetingColor } from '../../utils/meeting';
import { getElementPosition, getViewportSize } from '../../utils/window';
import { MDLandscapeNormalSpec } from '../Meeting/MeetingLayout/MDLandscapeNormal';
import { SMLandscapeNormalSpec } from '../Meeting/MeetingLayout/SMLandscapeNormal';

const ToolTipWrapper = ({
  onClose,
  children,
  parentId,
  width,
  distanceFromParent = 0,
  mobileNoCentered = false,
  hideCloseButton,
  closeButton,
  xPosition = 'center',
  className
}: {
  onClose?: () => void;
  children: React.ReactNode;
  parentId?: string;
  width: number;
  distanceFromParent?: number;
  mobileNoCentered?: boolean;
  hideCloseButton?: boolean;
  closeButton?: React.ReactNode;
  xPosition?: 'center' | 'left' | 'right';
  className?: string;
}) => {
  const triggerRef = React.useRef(null);

  const [position, setPosition] = React.useState<{
    top?: string;
    left?: string;
    right?: string;
    bottom?: string;
    transform?: string;
    maxHeight?: string;
  }>({
    top: '-1000px'
  });

  React.useEffect(() => {
    const viewportSize = getViewportSize();
    const viewportType = getViewportType({
      height: viewportSize.vh,
      width: viewportSize.vw
    });
    const isSmallScreen =
      viewportType === ViewportType.SM_LANDSCAPE ||
      viewportType === ViewportType.SM_PORTRAIT;
    if (parentId && !isSmallScreen && !mobileNoCentered) {
      const parentPosition = getElementPosition(
        document.getElementById(parentId)
      );
      const isHalfTopViewport =
        parentPosition.middleFromTop < viewportSize.vh / 2;
      const isLeftEdge =
        xPosition === 'right'
          ? false
          : parentPosition.middleFromLeft <
            width / (xPosition === 'left' ? 1 : 2);
      const isRightEdge =
        xPosition === 'left'
          ? false
          : parentPosition.middleFromRight <
            width / (xPosition === 'right' ? 1 : 2);

      const leftPosition = isLeftEdge
        ? `0`
        : `${
            xPosition === 'right'
              ? parentPosition.left
              : xPosition === 'left'
              ? 'auto'
              : parentPosition.middleFromLeft
          }px`;

      const rightPosition = isRightEdge
        ? `0`
        : `${
            xPosition === 'left'
              ? parentPosition.right
              : xPosition === 'right'
              ? 'auto'
              : parentPosition.middleFromRight
          }px`;

      setPosition({
        top: isHalfTopViewport
          ? `${parentPosition.middleFromTop + distanceFromParent}px`
          : 'auto',
        bottom: isHalfTopViewport
          ? 'auto'
          : `${parentPosition.middleFromBottom + distanceFromParent}px`,
        left: isRightEdge ? 'auto' : leftPosition,
        right: isLeftEdge ? 'auto' : rightPosition,
        transform:
          isRightEdge || isLeftEdge || xPosition !== 'center'
            ? 'none'
            : 'translate(-50%, 0)',
        maxHeight: isHalfTopViewport
          ? `${parentPosition.middleFromBottom - distanceFromParent - 20}px`
          : `${parentPosition.middleFromTop - distanceFromParent - 20}px`
      });
    } else {
      setPosition({
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '90vh'
      });
    }
  }, [parentId]);

  React.useEffect(() => {
    const closeOnClickOutside = (event) => {
      const isClickInside = triggerRef?.current?.contains(event.target);
      if (!isClickInside && onClose) {
        onClose();
      }
    };
    const timeout = setTimeout(() => {
      document.addEventListener('click', closeOnClickOutside);
    }, 100);
    return () => {
      document.removeEventListener('click', closeOnClickOutside);
      clearTimeout(timeout);
    };
  }, []);
  return (
    <div
      id="ToolTipWrapper"
      ref={triggerRef}
      className={`${!mobileNoCentered ? 'mobile-fixed' : ''} ${className}`}
      style={{ ...position }}
    >
      {children}
      {onClose && !hideCloseButton && (
        <div className="close-container" onClick={onClose}>
          {closeButton ?? <div className="btn">&times;</div>}
        </div>
      )}
      <style jsx global>{`
        .control-menu {
          background: ${meetingColor.transparentDark};
          border-radius: 10px;
          overflow: hidden;
          padding: 10px 20px;
        }
        .control-menu .list-group-item-action {
          cursor: pointer;
        }
        .control-menu .list-group-item {
          background: transparent !important;
          color: #ccc;
          font-weight: normal;
          font-size: 13px;
          letter-spacing: 0.3px;
          line-height: 1.4;
          border: none;
          padding: 0.75rem 1rem;
          display: flex;
          align-items: center;
          border-top: 0.5px solid ${meetingColor.border};
          white-space: pre-wrap;
          word-wrap: break-word;
        }

        #simpleShareMenu .list-group-item {
          color: #ccc !important;
          font-weight: normal !important;
          font-size: 13px !important;
          letter-spacing: 0.3px !important;
          line-height: 1.4 !important;
          border: none !important;
          padding: 0.75rem 1rem !important;
          border-top: 0.5px solid ${meetingColor.border} !important;
        }

        #simpleShareMenu .list-group-item:first-child {
          border-top: none !important;
        }

        #simpleShareMenu .list-group-item:hover {
          color: #fff !important;
        }

        .control-menu .list-group-item svg {
          height: auto;
          width: 25px;
        }
        .control-menu .copyUrl svg {
          width: 18px;
          height: auto;
          margin-left: 2px;
          margin-right: 4px;
        }
        .control-menu .list-group-item:first-child {
          border-top: none;
        }
        .control-menu .device-title {
          color: #ccc !important;
          font-size: 0.8em !important;
          letter-spacing: 1px !important;
          font-weight: normal !important;
        }

        .control-menu .device-title:hover {
          background: transparent !important;
        }
        .control-menu .list-group-item:hover {
          color: #fff !important;
        }
        .control-menu .list-group-item-secondary:hover,
        .control-menu .list-group-item-secondary {
          color: #fff !important;
        }
      `}</style>
      <style jsx>{`
        #ToolTipWrapper {
          position: fixed;
          z-index: ${zIndex.popupMenu};
          width: ${width}px;
          max-width: 100vw;
          overflow: auto;
          border-radius: 10px;
        }

        :global(.in-meeting.SMLandscape.normal-mode) #ToolTipWrapper {
          margin-left: ${parentId
            ? '0'
            : `${SMLandscapeNormalSpec.contentArea.left / 2}px`};
        }
        :global(.in-meeting.MDLandscape.normal-mode) #ToolTipWrapper {
          margin-left: ${parentId
            ? '0'
            : `${MDLandscapeNormalSpec.contentArea.left / 2}px`};
        }
        .close-container {
          position: absolute;
          right: 2px;
          top: 2px;
          cursor: pointer;
        }
        .btn {
          border: none;
          color: #fff;
          border-radius: 100px;
          width: 20px;
          height: 20px;
          background: rgba(0, 0, 0, 0.5);
          opacity: 0.6;
          font-size: 20px;
          line-height: 20px;
          padding: 0;
        }

        :global(.ps-container) {
          z-index: auto !important;
        }
      `}</style>
    </div>
  );
};

export default ToolTipWrapper;
