import React from 'react';
import { AiFillCopy } from 'react-icons/ai';
import {
  EmailIcon,
  LineIcon,
  LineShareButton,
  TelegramIcon,
  TelegramShareButton,
  WhatsappIcon,
  WhatsappShareButton
} from 'react-share';
import {
  IconCopyUrl,
  IconEmail,
  IconLine,
  IconTelegram,
  IconWhatsapp
} from '../../advisorHub/components/Common/HubIcons';
import { logEvent } from '../../analytics';
import {
  COPY_LINK_FAILED,
  COPY_LINK_SUCCESSFUL,
  DID_CLICK_COPY_LINK,
  DID_OPEN_SHARE_POPUP
} from '../../utils/constants';
import { QRCode } from '../QRCode';

interface Props {
  url: string;
  show?: boolean;
  title?: string;
  message?: string;
  onShare?: any;
  onClose?: () => void;
  position?: 'top' | 'bottom';
  darkMode?: boolean;
  noAnimated?: boolean;
  type?: 'menu' | 'popup' | 'newSession';
  qrUrl?: string;
  qrTitle?: string;
  urlOnly?: boolean;
}

const SimpleShareModal = ({
  url,
  show,
  title = '',
  message = '',
  onShare,
  onClose,
  position = 'bottom',
  darkMode,
  noAnimated = true,
  type = 'popup',
  qrUrl,
  qrTitle,
  urlOnly
}: Props) => {
  const [copied, setCopied] = React.useState(false);
  const iconColor = '#343740';
  const textColor = darkMode ? '#efefef' : '#343740';
  if (!show) return null;

  logEvent(DID_OPEN_SHARE_POPUP, DID_OPEN_SHARE_POPUP, {
    type: 'Simple share modal',
    shareUrl: url,
    shareTitle: title
  });

  const isBottomPosition = position === 'bottom';

  const copyViaTextarea = () => {
    const textArea = document.createElement('textarea');
    textArea.value = url;
    document.body.appendChild(textArea);
    textArea.select();
    try {
      document.execCommand('copy');
      onShare('copy');
      setCopied(true);
      logEvent(COPY_LINK_SUCCESSFUL, COPY_LINK_SUCCESSFUL, {
        urlToCopy: url
      });
    } catch (error) {
      logEvent(COPY_LINK_FAILED, COPY_LINK_FAILED, {
        urlToCopy: url,
        error: error.message
      });
    } finally {
      document.body.removeChild(textArea);
    }
  };

  const copyViaClipboard = () => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(url)
        .then(() => {
          onShare('copy');
          setCopied(true);
          logEvent(COPY_LINK_SUCCESSFUL, COPY_LINK_SUCCESSFUL, {
            urlToCopy: url
          });
        })
        .catch((e) =>
          logEvent(COPY_LINK_FAILED, COPY_LINK_FAILED, {
            urlToCopy: url,
            error: e
          })
        );
    } else {
      logEvent(COPY_LINK_FAILED, COPY_LINK_FAILED, {
        urlToCopy: url,
        error: 'Navigator clipboard unavailable'
      });
    }
  };

  const handleCopy = () => {
    logEvent(DID_CLICK_COPY_LINK, DID_CLICK_COPY_LINK, { urlToCopy: url });
    // Check if the page is inside an iframe
    if (window.self !== window.top) {
      copyViaTextarea();
    } else {
      copyViaClipboard();
    }
  };

  const shareAsPopup = (
    <div className="share-group" id="simpleSharePopup">
      <WhatsappShareButton
        url={url}
        title={message || title}
        separator=" "
        className="btnShare"
        beforeOnClick={() => onShare('whatsapp')}
        windowWidth={920}
        windowHeight={720}
      >
        <WhatsappIcon
          bgStyle={{ fill: iconColor }}
          size={32}
          round
          crossOrigin="anonymous"
        />
        <span className="btnShareSpan">WhatsApp</span>
      </WhatsappShareButton>
      <LineShareButton
        url={url}
        title={message || title}
        className="btnShare"
        beforeOnClick={() => onShare('line')}
      >
        <LineIcon
          bgStyle={{ fill: iconColor }}
          size={32}
          round
          crossOrigin="anonymous"
        />
        <span className="btnShareSpan">Line</span>
      </LineShareButton>
      <a
        href={`mailto:?subject=${title}&body=${message || title} ${url}`}
        target="_blank"
        className="btnShare email"
        onClick={() => onShare('email')}
      >
        <EmailIcon
          bgStyle={{ fill: iconColor }}
          size={32}
          round
          crossOrigin="anonymous"
        />
        <span className="btnShareSpan">Email</span>
      </a>

      <TelegramShareButton
        url={url}
        title={message || title}
        className="btnShare"
        beforeOnClick={() => onShare('telegram')}
      >
        <TelegramIcon
          bgStyle={{ fill: iconColor }}
          size={32}
          round
          crossOrigin="anonymous"
        />
        <span className="btnShareSpan">Telegram</span>
      </TelegramShareButton>

      <span
        className="btnShare page-url"
        id="share-button"
        onClick={handleCopy}
      >
        <AiFillCopy />
        {url} {copied && '(copied!)'}
      </span>

      {onClose && (
        <span className="btnShare close-modal" onClick={onClose}>
          CLOSE
        </span>
      )}
      <style jsx>{`
        .share-group {
          position: fixed;
          left: 40px;
          right: 40px;
          bottom: ${isBottomPosition ? '50px' : 'auto'};
          top: ${isBottomPosition ? 'auto' : '50px'};
          z-index: 30000;
          background: ${darkMode ? 'rgba(41,41,41,0.9)' : '#efefef'};
          border-radius: 10px;
          overflow: hidden;
          -webkit-animation: ${noAnimated
            ? 'none'
            : `slide-in-bottom 0.5s
            cubic-bezier(0.25, 0.46, 0.45, 0.94) both`};
          animation: ${noAnimated
            ? 'none'
            : `slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
            both`};
        }
        .share-group :global(.btnShareSpan) {
          display: block;
          color: ${textColor};
        }
        .share-group :global(.btnShare) {
          color: ${textColor};
          text-align: center;
          float: left;
          width: 50%;
          font-size: 0.9em;
          font-weight: normal;
          padding: 30px 0 !important;
          border: 0.5px solid ${darkMode ? '#000' : '#fff'} !important;
        }
        .share-group :global(.btnShare.page-url),
        .share-group .close-modal {
          width: 100%;
          padding: 10px 0 !important;
        }
        .share-group .close-modal {
          background: ${darkMode ? '#000' : '#ccc'};
          cursor: pointer;
        }
        .share-group :global(.btnShare.page-url svg) {
          height: 16px;
          width: auto;
          margin-right: 5px;
        }
        .share-group :global(.btnShare:hover) {
          background-color: ${darkMode ? '#000' : '#fff'} !important;
          text-decoration: none;
        }
        .share-group :global(svg) {
          height: 30px;
          width: auto;
          bottom: 0;
        }

        .btnShare.email {
          line-height: 24px;
        }

        @-webkit-keyframes slide-in-bottom {
          0% {
            -webkit-transform: translateY(${isBottomPosition ? 1000 : -1000}px);
            transform: translateY(${isBottomPosition ? 1000 : -1000}px);
            opacity: 0;
          }
          100% {
            -webkit-transform: translateY(0);
            transform: translateY(0);
            opacity: 1;
          }
        }
        @keyframes slide-in-bottom {
          0% {
            -webkit-transform: translateY(${isBottomPosition ? 1000 : -1000}px);
            transform: translateY(${isBottomPosition ? 1000 : -1000}px);
            opacity: 0;
          }
          100% {
            -webkit-transform: translateY(0);
            transform: translateY(0);
            opacity: 1;
          }
        }

        @media (min-width: 768px) {
          .share-group {
            bottom: ${isBottomPosition ? '70px' : 'auto'};
            top: ${isBottomPosition ? 'auto' : '70px'};
            position: fixed;
            left: calc(50% - 200px);
            width: 400px;
          }
        }
      `}</style>
    </div>
  );

  const shareAsMenu = (
    <div className="share-group" id="simpleShareMenu">
      <WhatsappShareButton
        url={url}
        title={urlOnly ? '' : message || title}
        separator=" "
        className="list-group-item list-group-item-action"
        beforeOnClick={() => onShare('whatsapp')}
        windowWidth={920}
        windowHeight={720}
      >
        <WhatsappIcon
          bgStyle={{ fill: 'transparent' }}
          size={32}
          round
          crossOrigin="anonymous"
        />
        WhatsApp
      </WhatsappShareButton>

      <LineShareButton
        url={url}
        title={message || title}
        className="list-group-item list-group-item-action"
        beforeOnClick={() => onShare('line')}
      >
        <LineIcon
          bgStyle={{ fill: 'transparent' }}
          size={32}
          round
          crossOrigin="anonymous"
        />
        Line
      </LineShareButton>

      <a
        href={`mailto:?subject=${title}&body=${message || title} ${url}`}
        target="_blank"
        className="list-group-item list-group-item-action"
        onClick={() => onShare('email')}
      >
        <EmailIcon
          bgStyle={{ fill: 'transparent' }}
          size={34}
          round
          crossOrigin="anonymous"
        />
        Email
      </a>

      <TelegramShareButton
        url={url}
        title={message || title}
        className="list-group-item list-group-item-action"
        beforeOnClick={() => onShare('telegram')}
      >
        <TelegramIcon
          bgStyle={{ fill: 'transparent' }}
          size={32}
          round
          crossOrigin="anonymous"
        />
        Telegram
      </TelegramShareButton>

      <span
        className="list-group-item list-group-item-action copyUrl"
        onClick={handleCopy}
      >
        <AiFillCopy />
        {copied ? 'URL Copied!' : 'Copy URL'}
      </span>
      <div className="list-group-item list-group-item-action">
        <QRCode title={qrTitle} value={qrUrl || url} round={10} size={160} />
      </div>
      {onClose && (
        <span className="btnShare close-modal" onClick={onClose}>
          CLOSE
        </span>
      )}
      <style jsx>{`
        a {
          color: #fff;
        }
      `}</style>
    </div>
  );
  const shareAsNewSession = (
    <div className="share-group" id="simpleSharePopup">
      <div className="row">
        <WhatsappShareButton
          url={url}
          title={message || title}
          separator=" "
          className="btnShare col-4"
          beforeOnClick={() => onShare('whatsapp')}
          windowWidth={920}
          windowHeight={720}
        >
          <IconWhatsapp />
          <span className="btnShareSpan">WhatsApp</span>
        </WhatsappShareButton>
        <LineShareButton
          url={url}
          title={message || title}
          className="btnShare col-4"
          beforeOnClick={() => onShare('line')}
        >
          <IconLine />
          <span className="btnShareSpan">Line</span>
        </LineShareButton>
        <TelegramShareButton
          url={url}
          title={message || title}
          className="btnShare col-4"
          beforeOnClick={() => onShare('telegram')}
        >
          <IconTelegram />
          <span className="btnShareSpan">Telegram</span>
        </TelegramShareButton>
      </div>
      <div className="row justify-content-center">
        <a
          href={`mailto:?subject=${title}&body=${message || title} ${url}`}
          target="_blank"
          className="btnShare email col-4"
          onClick={() => onShare('email')}
        >
          <IconEmail />
          <span className="btnShareSpan">Email</span>
        </a>

        <span className="btnShare col-4" onClick={handleCopy}>
          <IconCopyUrl />
          <span className="btnShareSpan">
            {copied ? 'URL Copied!' : 'Copy URL'}
          </span>
        </span>
      </div>
      <div className="QRCode">
        <QRCode value={qrUrl || url} round={1} size={120} />
      </div>
      {onClose && (
        <span className="btnShare close-modal" onClick={onClose}>
          CLOSE
        </span>
      )}
      <style jsx>{`
        .share-group {
          left: 20px;
          right: 20px;
          z-index: 30000;
          background: 'rgba(0,0,0,1)';
          border-radius: 10px;
          width: 300px;
        }
        .share-group :global(.btnShareSpan) {
          display: block;
          color: ${textColor};
          font-size: 0.8em;
        }
        .share-group :global(.btnShare) {
          color: ${textColor};
          text-align: center;
          float: left;
          width: 50%;
          font-size: 0.8em;
          font-weight: normal;
          padding: 30px 0 !important;
          border: 0.5px solid '#000' !important;
          height: 110px;
        }
        .share-group :global(svg) {
          height: 50px;
          width: auto;
          bottom: 0;
        }
        .icon-copy {
          height: 40px;
          width: auto;
          bottom: 0;
        }
        .btnShare.email {
          line-height: 24px;
        }
        .QRCode {
          padding: 24px;
        }
      `}</style>
    </div>
  );
  const getModel = (type) => {
    switch (type) {
      case 'popup':
        return shareAsPopup;
      case 'newSession':
        return shareAsNewSession;
      default:
        return shareAsMenu;
    }
  };
  return <>{show && url && onShare && <>{getModel(type)}</>}</>;
};

export default SimpleShareModal;
