import React from 'react';
import QR from 'qrcode.react';

interface Props {
  value: string;
  size?: number;
  title?: string;
  round?: number;
}

export const QRCode = ({ value, size = 200, title, round = 0 }: Props) => {
  return (
    <div className="qr-panel text-center">
      {title && <span>{title}</span>}
      <QR value={value} size={size} />
      <style jsx>{`
        .qr-panel {
          background: #fff;

          width: ${size + 20}px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
          padding: 10px 10px 5px;
          border-radius: ${round}px;
          margin: auto;
        }
        span {
          display: block;
          font-size: 13px !important;
          letter-spacing: 0 !important;
          font-weight: normal !important;
          text-shadow: none !important;
          line-height: 1.2 !important;
          margin-bottom: 10px;
          color: #222 !important;
          text-transform: none !important;
        }
      `}</style>
    </div>
  );
};
