import { meetingColor } from '../../../utils/meeting';

export const MDGlobalSpec = {
  logoSectionHeight: 50,
  meetingControlsHeight: 60
};

const MDGlobal = () => (
  <style jsx global>{`
    .MDLandscape #videos-wrapper > .scroll-container {
      margin: 0 auto auto;
    }
    .client .MainControls .meeting-button,
    .MD .MainControls .meeting-button {
      padding: 0 8px;
      margin: auto 5px;
    }
    .client .MainControls .meeting-button:after,
    .MD .MainControls .meeting-button:after {
      content: '';
      display: block;
      width: 1px;
      background: #fff;
      position: absolute;
      right: -5px;
      height: 20px;
      opacity: 0.2;
    }
    .client .MainControls #meetingEndButton:after,
    .MD .MainControls #meetingEndButton:after {
      display: none;
    }
    .client .MainControls .meeting-button span,
    .MD .MainControls .meeting-button span {
      display: none;
    }
    .client .MainControls .meeting-button svg,
    .MD .MainControls .meeting-button svg {
      width: 34px;
      height: auto;
    }
    .client .meeting-controls,
    .MD .meeting-controls {
      display: flex;
      position: fixed;
      align-items: center;
      left: 0;
      right: 0;
      justify-content: center;
      bottom: 10px;
      height: 0;
      margin-bottom: 20px;
    }
    .client.no-controls .meeting-controls,
    .client.no-controls .controls-wrapper,
    .MD.no-controls .meeting-controls,
    .MD.no-controls .controls-wrapper {
      height: 0;
    }
    .client .controls-wrapper,
    .MD .controls-wrapper {
      position: relative;
      display: flex;
      align-items: center;
    }
    .client .controls-group,
    .MD .controls-group {
      display: flex;
      border-radius: 100px;
      padding: 5px;
      transform: translateY(0);
      opacity: 1;
    }
    .client .group-main,
    .MD .group-main {
      display: flex;
    }
    .client.no-controls .MainControls,
    .MD.no-controls .MainControls {
      pointer-events: none;
    }
    .client.no-controls .controls-group,
    .MD.no-controls .controls-group {
      transform: translateY(100%);
      opacity: 0;
      pointer-events: none;
    }
    .client .group-item,
    .MD .group-item {
      display: flex;
    }
    .client .group-item:first-child,
    .MD .group-item:first-child {
      padding-left: 0;
    }
    .client .last-group,
    .MD .last-group {
      border-right: none;
      padding-right: 0;
    }
    .MD .slide-menu-only-group {
      display: none;
    }

    .meet-fullscreen {
      position: fixed;
      top: 0;
      right: 5px;
      height: ${MDGlobalSpec.logoSectionHeight}px;
      display: flex;
      align-items: center;
    }
    .client .control-hide-button,
    .MD .control-hide-button {
      background: ${meetingColor.transparentDark2};
      border-radius: 100px;
      padding: 5px;
      position: absolute;
      right: -38px;
      transform: translateX(0) rotate(0);
    }
    .client.no-controls .control-hide-button,
    .MD.no-controls .control-hide-button {
      right: 50%;
      transform: translateX(50%) rotate(180deg);
    }
    .client .control-hide-button svg,
    .MD .control-hide-button svg {
      display: block;
      width: 20px;
      height: auto;
    }

    .MD.in-meeting .document-fullscreen {
      top: ${MDGlobalSpec.logoSectionHeight}px !important;
    }

    .MD .logo-section {
      height: ${MDGlobalSpec.logoSectionHeight}px;
    }
    .MD .timer-section {
      top: ${MDGlobalSpec.logoSectionHeight}px;
    }
    .MD .enlarge-video .btn-close {
      top: ${MDGlobalSpec.logoSectionHeight}px;
    }

    .MD .studio-control {
      position: fixed;
      top: ${MDGlobalSpec.logoSectionHeight}px;
      right: 60px;
      margin-top: 12px;
    }
    .MD .video-wrapper-bg {
      top: ${MDGlobalSpec.logoSectionHeight}px;
    }
    .tile-mode.MD #participant-wrapper {
      top: ${MDGlobalSpec.logoSectionHeight}px;
    }

    @media (max-width: 440px) {
      .client .control-hide-button {
        right: -32px;
      }
      .client .MainControls .meeting-button svg {
        width: 30px;
      }
      .client .MainControls .meeting-button {
        padding: 0 6px;
      }
    }

    @media (max-width: 375px) {
      .client .MainControls .meeting-button {
        padding: 0 4px;
      }
    }
  `}</style>
);

export default MDGlobal;
